:root {
  --drawerWidth: 250px;
  --imgSize: 50px;
  --gray: #c0c0c0;
}
.root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  background-color: #fcfcfc !important;
}
.root a{
  text-decoration: none;
  color: #fff;
}
.menuWrapper, .menuWrapperClose {
  width: var(--drawerWidth);
  min-height: 100vh;
  display: flex;
  position: fixed;
  transform: translateX(0);
  transition: transform .5s ease-in-out;
  background-color: #fff !important;
  z-index: 5;
}   
.menuWrapperClose {
  transform: translateX(-110%);
}
.drawer {
  width: var(--drawerWidth);
}
.drawerPaper {
  width: var(--drawerWidth);
  background-color: #fff !important;
  border-right: 1px solid var(--gray);
}
.drawerLogo{
  /* width: 100%; */
  height: 100px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.drawerLogo p, .menu p{
  font-weight: 900;
}
.list{
  margin-top: 30px !important;
}
.listItem{
  margin: 15px 0;
}
.listIcon{
  width: 45px;
  min-width: auto !important;
} 
.listIcon svg{
  font-size: 1.5rem;
  color: black !important;
  font-weight: 500 !important;
}
.listText span{
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}
.appBar {
  width: 100%;
  z-index: 2 !important;
  box-shadow: none !important;
  background-color: #fcfcfc !important;
}
.layoutToolbar {
  background-color: #fcfcfc !important;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 50px;
  height: 50px;
}
.menu {
  display: flex;
  align-items: center;
}
.menuToolbar {
  margin-bottom: 10;
} 
.adminHeader{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.adminHeader h3{
  font-weight: 700;
  font-size: 1.3rem;
  margin: 0;
  margin-left: 10px;
}
.commonAdminHeader{
  display: flex;
align-items: center;
justify-content: space-between;
}
.profileInfo{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
}
.bell{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.bellCircle{
  position: absolute;
  top: 0;
  right: 3px;
}
.avatar{
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--imgSize);
  height: var(--imgSize);
  border-radius: 50%;
}
.avatarImg{
 width: 100%;
 border-radius: 50%;
}
.addHeader{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  }
.adminSearch{
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid var(--gray);
  padding: 14px 15px;
  border-radius: 3px;
}
/* .adminInput{
  outline: none;
  background: none;
  border: none;
  color: black;
  font-size: 1.1rem;
}
.adminInput::placeholder {
  color: var(--gray) !important;
  font-size: 1.1rem;
} */
.adminSearch svg{
  color: var(--gray);
  font-size: 1.3rem;
}
.actions{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.action{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #206575;
  width: 170px;
  border-radius: 3px;
  cursor: default;
  border: none;
  font-size: 1rem;
}
.action:first-child{
  margin-right: 10px;
  margin-bottom: 10px;
}
.action p, .action svg{
  color: #fff;
}
.action svg{
  color: #fff;
  font-size: 1.4rem;
  margin-right: 5px;
}
.pageContent {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: inherit;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #fcfcfc !important;
}
.marginFlex {
  margin-top: 310px;
}
.marginNone{
  margin-top: 100px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  margin-top: 5px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #708328;
}

input:checked + .slider::before {
  left: -11px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #708328;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (min-width: 320px) {
  .layoutToolbar{
    padding: 10px 20px;
  }
}
@media screen and (min-width: 390px) {
  .action:first-child{
    margin-bottom: 0;
  }
  .marginFlex{
    margin-top: 270px;
  }

}
@media screen and (min-width: 475px) {
  .layoutToolbar{
    padding: 20px 30px;
  }
  .logo{
    width: 70px;
    height: 70px;
  }
  .marginNone{
    margin-top: 120px;
  }
}
@media screen and (min-width: 679px) {
  .adminHeader h3{
    font-size: 1.4rem;
  }
  .addHeader{
    margin-top: 20px;
  }
  .adminSearch{
    margin-right: 10px;
  }
}
@media screen and (min-width: 752px) {
  .actions{
    margin-top: 0;
  }
  .marginFlex{
    margin-top: 240px;
  }
}
@media screen and (min-width: 1024px) {
  .appBar {
    width: calc(100% - var(--drawerWidth)) !important;
  }
  .drawerLogo{
    margin-top: 0;
  }
  .layoutToolbar{
    padding: 30px 40px;
  }
  .menuWrapperClose {
    transform: translateX(0%);
  }
  .menu {
    display: none;
  }
  .adminHeader{
    margin-top: 0;
  }
  .action:first-child{
    margin-right: 20px;
  }
  .pageContent {
    width: calc(100% - var(--drawerWidth)) !important;
    align-self: flex-end;
  }
  .marginFlex{
    margin-top: 140px;
  }
  
}