/* body {
    overflow-x: hidden;
} */
.prl-input-right {
  width: 100%;
  margin-left: 0em;
  margin-bottom: 1em;
}

.prl-input-right::placeholder {
  color: gray;
  font-size: 14px;
  padding-left: 10px;
}


.prl-input-right-Otp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prl-input-right-s {
  margin-left: 0em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
}

.SU_S_prl-input {
  width: 480px;
  margin-left: 40px;
  max-width: 100%;
  height: 55px;
  box-sizing: border-box;
  background: white;
  color: #000000;
  cursor: pointer;
  border: 1px solid #c7c7c7;
  padding-left: 1.3em;
  font-size: 0.9em;
  font-family: "Inter", sans-serif;
  border-radius: 0.5em;
}

.prl-input-dd {
  position: relative;
}

.SU_S_prl-input-dd-s {
  width: 100%;
  height: 51px;
  background: white;
  color: #000000;
  cursor: pointer;
  border: 1px solid #c7c7c7;
  padding-left: 1.5em;
  font-size: 0.8em;
  font-family: "Inter", sans-serif;
  border-radius: 0.5em;
}

.SU_S_prl-input-dd-ss {
  width: 30%;
  height: 51px;
  background: white;
  color: #000000;
  cursor: pointer;
  border: 1px solid #c7c7c7;
  padding-left: 1.5em;
  font-size: 0.8em;
  font-family: "Inter", sans-serif;
  border-radius: 0.5em;
}

.submit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;

}

.already {
  display: flex;
  justify-content: center;
  align-items: center;
}

.already p {
  color: gray;
  font-size: 18px;
  font-weight: 500;
}

.su-login-btn {
  background-color: transparent;
  border-width: 0;
  color: #FF5C00;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.prl-input-arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 2em;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 0.2em;
  margin-right: 0.2em;
  pointer-events: none;
}

.dobText {
  font-weight: 500;
}

.prl-input-arrow1 {
  position: absolute;
  top: -5;
  right: 0px;
  width: 2em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-top: 0.2em;
  margin-right: 0.2em;
  pointer-events: none;
}

.prl-input-err-txt {
  position: absolute;
  right: 0;
  margin: 0px;
  color: #fb7b7c;
  font-size: 0.8em;
  font-family: "Inter", sans-serif;
}

/*******************************/

.mainContainer {
  width: 100vw;
  overflow-x: hidden
}

.LeftContainer {
  background-color: #452b4e;
  position: relative;
  padding: 20px
}

.ImagBckground {
  width: 402px;
  height: 115px;
  margin-left: 50px;
  margin-top: 30px;

}

.LogoHeading {
  position: absolute;
  color: #fffefc;
  font-size: 35px;
  font-weight: 700;
  left: 4.5em;
  top: 1.5em;
}

.backgroundImag {
  width: 90%;
  height: 60%;
  margin: 25px auto;
  display: block;
  padding-top: 20px;
}

.Google {
  width: 270px;
  display: block;
  margin: 20px auto;
}

.react-tel-input .form-control {
  height: 50px !important;
  width: 100% !important;
}

.MuiCheckbox-root {
  color: #ff9b9b !important;
}

.MuiSvgIcon-root {
  height: 1.5em !important;
  width: 1.5em !important;
}



/* signup  */
::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  cursor: pointer;

  color: black
}

.mobileField::placeholder {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;

}

select:invalid {
  color: gray;
}

.buttonStyle {
  color: #ffffff;
  background: #FF5C00;
  border-radius: 8px;
  width: 650px;
  height: 64px;
  font-size: 18px;
  text-transform: none;
  font-weight: 600;
}

.disableButtonStyle {
  width: 650px;
  height: 64px;
  background-color: #F0F0F0;
  border-radius: 8px;
}

.emailPlace::placeholder {
  padding-left: 10px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.MuiSelect-icon {
  right: "10px";
  position:'relative';
  top: 12 !important;
  cursor: pointer !important;
}