.recharts-legend-wrapper,
.recharts-default-legend {
  text-align: left !important;
}
.recharts-legend-item-text {
  color: rgb(15, 23, 42) !important;
}
/* MAin */
.main-container {
  width: 100%;
  margin: 0px auto;
  max-width: 1200px;
}
.main-container > div {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 2.5rem;
}

/* OVERVIEW CARD */
.card-container {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}
.card-container .title {
  color: #0f172a;
  font-size: 0.875rem;
  font-weight: 600;
  word-wrap: break-word;
}
.card-container .card-container__body {
  align-self: stretch;
  height: 110px;
  padding: 10px 15px;
  background-color: white;
  border-radius: 10px;
  border: 1px #dce1eb solid;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}
.card-container__body .section-container {
  flex: 1 1 0;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
}
.icon-container {
  width: 25px;
  height: 25px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-overview {
  width: 100%;
  height: 100%;
}

/* CHARTS */
.chart-container {
  padding-top: 16px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

/* HEADER */
.dates-container {
  display: inline-flex;
  gap: 0.7rem;
  padding: 0 0.25rem;
}
.dates-container .date_boxs .date_input input {
  min-width: 122px !important;
}
.dates-container .MuiOutlinedInput-notchedOutline {
  border-color: #94a3b8 !important;
  border-radius: 10px;
}
.header-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}
.header-container .title {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  gap: 4px;
  align-items: baseline;
  justify-content: flex-start;
}
.header-container .select-container {
  height: 37px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.select-container .MuiSelect-selectMenu {
  border: 1px solid #e2e8f0;
  background: white;
  border-radius: 10px;
  padding: 5px 30px 5px 10px;
}

.select-container .MuiSelect-select:focus {
  border-radius: 10px;
}

.select-container .MuiListItemText-root,
.select-container .MuiListItemText-primary.MuiTypography-body1 {
  font-weight: 500 !important;
  font-size: 0.688rem !important;
}

.select-container .MuiSelect-icon {
  top: unset;
  right: 10px;
  color: inherit;
}

.chart-month .recharts-wrapper > .recharts-legend-wrapper {
  left: 85px !important;
}
.chart-week .recharts-wrapper > .recharts-legend-wrapper {
  left: 90px !important;
}

.recharts-text.recharts-cartesian-axis-tick-value > tspan,
.recharts-layer.recharts-cartesian-axis-tick > g > text {
  font-size: 0.563rem;
}

.recharts-legend-item > .recharts-legend-item-text {
  font-size: 0.625rem;
}
