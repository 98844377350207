#table {
    width: 97%;
    border-top: 2px solid black;
    border-spacing: 0;
    margin: 0 20px;
    margin-top: 10px;
}

#table thead {
    width: 100%;
    border-collapse: initial;
    padding: 10px 5px;
    height: 50px;
}

#table th {
    border-bottom: 2px solid black;
    font-weight: 600;
}

#table th svg {
    color: var(--gray);
    margin-bottom: -5px;
}

.dividerLine {
    height: 20px;
    display: inline-block;
}

.tableBody {
    width: 90%;
}

#table input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 3px solid black;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableBody tr {
    background-color:#F5F5F7;
    border-radius: 20px;
    height: 50px;
    padding: 45px;
    margin: 20px;
}

.tableBody td {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.tableBody td:nth-child(2) svg {
    background-color: #d9d9d9;
    border-radius: 3px;
    padding: 3px;
    margin-top: 3px;
}

.tableBody td:nth-child(3) {
    width: 160px;
}

#table th:nth-child(3):first-of-type {
    width: 200px;
}

.tableBody td button {
    border: none;
}

.tableBody td button svg,
.tableBody td a svg {
    font-size: 1.4rem;
}

.tableActions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableFooter {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: space-between;
    width: 320px;
    margin-right: 40px;
}

.pageWrapper {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tableFooter p {
    color: #7ca1a4;
    font-size: 1.1rem;
    font-weight: 600;
}

.pagination {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pagination span,
.pageWrapper svg {
    font-size: 1.1rem;
    font-weight: 500;
}

.pageWrapper svg {
    font-size: 1.4rem;
}

.pageWrapper button {
    background: none;
    border: none;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.currentPage {
    background-color: #176876;
    color: white;
    border-radius: 5px;
    padding: 0px 5px;
}

.reset {
    width: 195px;
    padding: 15px 0;
    color: #1F6575;
    font-size: 1rem;
    font-weight: 600;
    background-color: #fcfcfc;
    border: 1px solid #81999f;
    margin-top: 20px;
}

.create {
    width: 195px;
    padding: 15px 0;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    background-color: #1F6575;
    margin-top: 20px;
}


.cancel,
.delete {
    width: 150px;
    border-radius: 6px;
    padding: 15px 0;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    background-color: #b91111;
    margin-top: 20px;
}

.cancel {
    background-color: #fcfcfc;
    border: 1px solid #81999f;
    color: #81999f;
}

.custom-container {
    width: 100%;
}

.custom-date-picker {
    position: relative;
    display: inline-block;
}

.custom-date-picker input {
    width: 100%;
    height: 55px;
}

.custom-date-picker .calendar-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}