
.step-box {
  border: 3px dotted #1156b6;
}

.step-box-active {

  border: 2px solid #1156b6;
}
.MuiGrid-align-items-xs-center {
  height: 100%;
}
.borderUi {
  position:absolute;
  inset:0;
  z-index: -1;
  top: 40px;
}
.StepperTitle {
  margin-top: 10px;
  width: 200px;
  margin-left: -100px;
  position: absolute;
  bottom: -30px;
  left: 50%;
  text-align: center;

}

/* .RegisterForm {
  max-height: 399px;
  overflow: auto
} */

@media (max-height: 768px){
  .RegisterForm {
 
    overflow: auto;
    padding-right: 15px;
  }
}