/* Header Styles */
.container{
    width: 100%;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.wrapper{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100% !important;
    height: 420px;
    position: relative;
    background-image: linear-gradient(to right, #3C7481, #154955);
    display: flex;
    justify-content: center !important;
    align-items: flex-start;
    padding: 20px 10px;
    overflow: hidden;
    z-index: 10;
}
.headerLeft{
    width: 100%;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin: 0 !important;
}
.h1{
    color: white;
    text-align: left;
    font-size: 1.6rem;
    padding: 0;
    margin: 0;
    max-width: 500px;
}
.searchBox{
    width: 100%;
    height: 86px;
    max-width: 764px;
    border: 2.372px solid white;
    border-radius: 30px;
    padding: 10px;
    margin-top: 30px !important;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(28.73729705810547px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.selectWrapper{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 10px 20px;
    border-radius: 31.427px;
    background: linear-gradient(180deg, #FFF54B 0%, #FCB910 100%);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset;
}
select{
    width: 100%;
    border: none;
    background: none;
    outline: none;
    font-size: 1.1rem;
    text-transform: uppercase;
    color: #212121;
    appearance: none;
}
.selectWrapper svg {
  position: absolute;
  right: 1.4rem;
  pointer-events: none;
  color: #212121;
}
.searchWrapper{
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border-radius: 31.427px;
}
.input{
    outline: none;
    background: none;
    border: none;
    color: #fff;
    font-size: 1rem;
}
input::placeholder{
    color:#fff !important;
}
.searchIcon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(180deg, #FFF54B 0%, #F4C952 100%);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset;  
    display: flex;
    justify-content: center;
    align-items: center;   
}
.icon{
    font-size: 1.2rem;
    color: #212121;
}
.image{
    width: 100% !important;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;
}
.headerImage{
    width: 100%;
    position: absolute;
    bottom: -100px;
    z-index: 5;
}

/* Content Styles */
.contentWrapper{
    padding: 0 10px 50px;
    z-index: 7;
    margin-top: -5px;
    position: relative;
}
.tabMenu{
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.tabItemWrapper{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin: 5px !important;
}
.tabItem, .tabItemActive{
    background-color: #E3E3E3;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    transition: background-color .3s ease-in-out;
}
.tabItemActive{
    background-color: #FFD735;
}
.tabText{
    color: #212121;
    text-transform: uppercase;
    font-size: .85rem;
    font-weight: 500;
    cursor: default;
}
.border, .borderActive{
    border: 2px solid #FFD735;
    opacity: 0;
    border-radius: 5px;
    margin-top: 5px;
    transition: border .3s ease-in-out, opacity .3s ease-in-out;
}
.borderActive{
    opacity: 1;
}
.tabItemWrapper:hover .tabItem{
    background-color: #FFD735;
}
.tabItemWrapper:hover .tabText{
    font-weight: 600;
}

/* Heading Styles */
.heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    z-index: 9;
}
.headingTitle{
    display: flex;
    align-items: center;
}
.headingTitle p{
    color: #858585;
    font-weight: 600;
    font-size: .9rem;
    margin: 0;
    padding: 0;
    margin-right: 3px;
    cursor: default;
}
.arrowRight{
    margin-top: 3px;
    margin-right: 5px;
}
.headingIcons{
    max-width: 130px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px !important;
    z-index: 9;
}
.iconWrapper{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: transform .6s ease-in-out;
}
.iconWrapper:hover {
    transform: scale(1.06);
}
.filter, .window, .listIcon{
    font-size: 1rem;
}
.view{
    width: calc(100% - 60px);
    max-width: calc(180px - 60px);
    display: flex;
    align-items: center;
    justify-content: space-between; 
}
.content{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 7;
    overflow: hidden;
}
.bgCircle{
    width: 1078px;
    height: 1078px;
    border-radius: 50%;
    position: fixed;
    overflow: hidden;
    background-color: #E5F3FF;
    right: -30%;
    top: 700px;
}
.showMore{
    background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.27);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  align-self: center;
  margin: 10px 0;
}
.showMore P{
    color: #1220C1;
    margin-left: 10px;
    cursor: default;
}


/* Media Queries */
@media screen and (min-width: 320px) {
    .image{
      bottom: -60px;
    }
    .headingTitle p{
        font-size: 1rem;
    }
}
@media screen and (min-width: 350px) {
    .header{
        padding: 40px 20px;
    }
    .contentWrapper{
        padding: 0 20px 50px;
    }
}
@media screen and (min-width: 370px) {
    .headerImage{
        bottom: -110px;
    }
}
@media screen and (min-width: 400px) {
    .tabMenu{
        justify-content: flex-start;
    }
    .tabItemWrapper{
        max-width: 30%;
    }
    .headingTitle p{
        font-size: 1.2rem;
    }
}
@media screen and (min-width: 450px) {
    .headerImage{
        width: 70%;
    }
}
@media screen and (min-width: 600px) {
    .searchBox{
       flex-direction: row;
       justify-content: space-between;
       height: auto;
       border-radius: 43.286px;
       padding: 8px;
    }
    .selectWrapper{
        width: 170px;
        height: 62.438px;
        padding: 0 20px;
    }
    select{
        font-size: 1.2rem;
    }
    .searchWrapper{
        width: calc(100% - 219px);
        margin-top: 0;
    }
    .input{
        width: calc(100% - 60px)
    }
    .searchIcon{
        width: 50px;
        height: 50px;
    }
    .icon{
        font-size: 1.8rem;
    }
    .headerImage{
        bottom: -130px;
    }
    .tabMenu{
        justify-content: space-between;
    }
    .tabItemWrapper{
        width: 18%;
        max-width: 180px;
}
.headingIcons{
    margin-top: 0 !important;
}
}
@media screen and (min-width: 650px) {
    .header{
        padding: 50px;
    }
    .selectWrapper{
        width: 219px;
    }
    .searchWrapper{
        width: calc(100% - 270px);
        margin-top: 0;
    }
}
@media screen and (min-width: 700px) {
    .headerImage{
        max-width: 400px;
        bottom: -130px;
    }
}
@media screen and (min-width: 800px) {
    .h1{
        font-size: 2rem;
    }
    .tabText{
        font-size: 1.1rem;
    }
    .headingTitle p{
        font-size: 1.4rem;
        margin-right: 10px;
    }
    .arrowRight{
        font-size: 1.5rem;
        margin-right: 10px;
    }
    .headingIcons{
        max-width: 220px !important;
    }
    .iconWrapper{
        width: 50px;
        height: 50px;
    }
    .view{
        max-width: calc(100% - 100px);
    }
    .filter, .window, .listIcon{
        font-size: 1.4rem;
    }
}
@media screen and (min-width: 900px) {
    .header{
        align-items: center !important;
        justify-content: space-between !important;
        height: 300px;
    }
    .image{
        position: static;
    }
    .headerImage{
        max-width: 400px;
        bottom: 0px;
    }
}
@media screen and (min-width: 1024px) {
    .headerImage{
        max-width: 450px;
    }
}
@media screen and (min-width: 1100px) {
    .headerImage{
        max-width: 500px;
    }
}
@media screen and (min-width: 1200px) {
    .contentWrapper{
        padding: 0 50px 50px;
    }
    .headerImage{
        max-width: 600px;
        bottom: 0px;
        right: 30px;
    }
}
@media screen and (min-width: 1300px) {
    .headerImage{
        right: 60px;
    }
}
