.react-tel-input .form-control {
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    font-size: 16px !important;
    border-bottom: 2px solid #FF5C00 !important;
}


.react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
}

.react-tel-input .flag-dropdown:hover {
    background-color: transparent !important;
}

.mobileField::placeholder {
    font-size: 16px;
    font-weight: 400;
    padding-left: 27px;
    color: #FFF;
    font-style: normal;
    line-height: normal;
    opacity: 0.7;
}

.loginemail::placeholder {
    color: #d5d5d5;
    text-align: center;
    opacity: 0.7;
}

.google_button:hover {
    background-color: rgba(255, 255, 255, 0.13) !important;
}
.google_button {
    text-transform: none !important;
}
.gogle_button-container {
    display: flex;
    width: 100%;
}