.description-wrapper > * {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.courses {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
  font-family: "Inter", sans-serif;
  padding: 0 !important;
  padding-bottom: 30px !important;
  margin: 0 !important;
}
.courses p {
  margin: 0;
  padding: 0;
  color: #212121;
  cursor: default;
}
.cardContainer,
.cardContainerList {
  width: 100%;
  max-width: 314px !important;
  position: relative;
  text-decoration: none;
  color: black;
  background-color: white;
  margin: 15px 0 !important;
  border-radius: 15.073px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.27);
}
.cardWrapper,
.cardWrapper2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15.073px;
}
.cardWrapper2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(1, 123, 147, 0.93) 0%,
    rgba(0, 33, 41, 0.97) 100%
  );
}
.spacingWrapper {
  display: flex;
  flex-direction: column !important;
  padding: 10px;
  border-radius: 15.073px;
}
.imgWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 15.073px;
}
.thumbnailImg {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15.073px;
}
.thumbnail {
  width: 100%;
  height: 180px;
  border-radius: 15.073px;
}
.playIcon {
  font-size: 2rem;
  position: absolute;
}
.courseHeading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.courseHeadingLeft {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.courseName,
.courseNameList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3px 8px;
}
.circleIcon,
.circleIconList {
  font-size: 0.6rem;
  margin-right: 5px;
}
.courseName p, .courseNameList p {
  font-size: 0.8rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
  max-width: auto;
}
.courseName p{
  max-width: 100px;
}
.courseRatingInfo,
.courseRatingInfoList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.courseRatingInfoList, .coursaNameList{
  width: 33%;
  max-width: 210px;
}
.courseRatingInfoList:last-child{
  max-width: 270px;
}
.courseRatingInfo p,
.courseRatingInfo span,
.courseRatingInfoList p,
.courseRatingInfoList span {
  font-size: 0.5rem;
  font-weight: 600;
}
.courseRatingInfo span,
.courseRatingInfoList span {
  color: #939393;
}
.courseDesc,
.courseDesc2 {
  margin-top: 20px;
}
.courseDesc p,
.courseDesc2 p {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 46px;
}
.instructor {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0;
  margin: 10px 0;
}
.instructor p {
  font-size: 0.85rem;
  font-weight: 600;
  text-align: left;
  color: white;
  margin-left: 10px;
}
.purchaseInfo,
.purchaseInfo2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price,
.cartPurchased,
.cart,
.priceList {
  width: 45%;
  display: flex;
  align-items: center;
}
.rupeeIcon {
  font-size: 1.5rem;
}
.price p,
.priceList p {
  font-size: 1.4rem;
  font-weight: 700;
  color: #00be10;
}
.cart,
.cartPurchased,
.cartList,
.cartList2,
.cartPurchasedList {
  width: 40%;
  max-width: 110px;
  border-radius: 8.222px;
  background: linear-gradient(133deg, #f93a3a -26.6%, #a81fe1 118.85%);
  padding: 15px 10px;
}
.cart{
  justify-content: center;
}
.cart2{
  background: white;
}
.cart p,
.cartList p {
  font-size: 0.8rem;
  color: white;
  text-align: center;
}
.cart2 p{
  color: #316773;
}
.cartPurchased,
.cartPurchasedList {
  border-radius: 8.222px;
  background: #e9e9e9;
  justify-content: center;
}
.cartPurchased p,
.cartPurchasedList p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #00be10;
  text-align: center;
}
.check {
  font-size: 1rem;
}
.spacingWrapper2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 15.073px;
}
.courseHeadingWrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.courseHeading2 {
  width: 110px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28.795px;
  background: #fff;
}
.courseHeading2 p {
  color: #1220c1;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.65rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.courseDesc2 {
  margin-top: 15px;
}
.shortDesc {
  color: white;
  margin-top: 10px;
}
.shortDesc p {
  font-size: 0.75rem;
  font-weight: 400;
  color: white;
  text-align: left;
  line-height: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 44px;
}
.courseInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}
.courseInfoLeft, .courseInfoRight {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.divider {
  width: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.courseRatingInfo2 {
  display: flex;
  align-items: center;
}
.courseRatingInfo2 p {
  font-size: 0.5rem;
  color: white;
  font-weight: 600;
}
.starIcon2 {
  font-size: 0.8rem;
}
.info, .info1{
  height: 20px;
  justify-content: center;
}
.info1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 5px;
}
.info1 p {
  font-size: 0.6rem;
  color: white;
}
.info1 p:last-child {
  font-size: 0.7rem;
  font-weight: 600;
}
.line {
  border: 0.5px solid white;
  height: 100px;
}
.info {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  border-bottom: 0.5px dashed white;
}.info
.info:last-child{
  border-bottom: none;
}
.info p {
  color: white;
  font-size: 0.65rem;
}
.infoIcon {
  font-size: 1rem;
}
.purchaseInfo2 {
  align-self: flex-end;
  margin-top: 15px;
}

/* ListView Styles */
.cardContainerList {
  width: 100%;
  max-width: 100% !important;
  position: relative;
  margin: 15px 0 !important;
}
.cardWrapperList,
.cardWrapperList2,
.cardWrapperList3 {
  height: 100%;
  display: flex;
  border-radius: 15.073px;
  padding: 10px;
}
.cardWrapperList2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(1, 123, 147, 0.93) 0%,
    rgba(0, 33, 41, 0.97) 100%
  );
}
.cardWrapperList3{
    flex-wrap: wrap;
    padding: 0;
}
.cardWrapperList3 p, .cardWrapperList3 svg{
    color: #fff;
}
.imgWrapperList {
  width: 30%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 15.073px;
  margin-right: 10px;
}
.thumbnailImgList {
  width: 100%;
  max-width: 300px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15.073px;
}
.thumbnailList {
  width: 100%;
  height: 180px;
  border-radius: 15.073px;
}
.listInfoLeft, .listInfoLeft2 {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.listInfoLeft2{
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}
.listContent {
  display: flex;
  flex-direction: column;
}
.courseHeadingList, .courseHeadingList2 {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
.courseDescList2, .courseInfoList, .purchaseInfoList2{
    padding: 0 10px;
}
.courseHeadingList2{
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
}
.courseHeadingList p,
.courseHeadingList span,
.courseHeadingList2 p {
  font-size: 0.6rem;
  font-weight: 600;
}
.courseDesc3 p{
    font-size: .8rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 900px;
}
.boardIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.courseDescList, .courseDescList2 {
  margin: 10px 0 10px;
}
.courseDescList p, .courseDescList2 p {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
}
.courseDescList2 p {
    font-size: 0.7rem;
  }
.purchaseInfoList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.purchaseInfoList2{
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
}
.priceWrapper {
  display: flex;
}
.priceList {
  position: relative;
  width: auto;
}
.priceList:last-child {
  margin-left: 10px;
}
.priceList p {
  font-size: 1rem;
}
.priceList:last-child p {
  color: #bdbcbc;
  font-size: 0.9rem;
}
.priceList2 p{
    font-size: .8rem;
}
.rupeeIconList {
  font-size: 1.1rem;
  font-weight: 700;
}
.stroke {
  width: 100%;
  border: 1px solid black;
  position: absolute;
  transform: rotate(10deg);
}
.cartWrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.cartWrapperList{
    margin-top: 0;
}
.wishlistIconWrapperList {
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.27);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.wishlistIconWrapperList3{
    width: 20px;
    height: 20px;
}
.heartIconList {
  font-size: .8rem;
}
.heartIconList2 {
  font-size: .7rem;
}
.cartList,
.cartPurchasedList {
  width: calc(100% - 80px);
  max-width: 150px;
  border-radius: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.cartList2, .cartPurchasedList2 {
    width: 100px;
    background: #fff;
    padding: 5px;
}
.cartPurchasedList2{
    background: #e9e9e9;
}
.cartList p,
.cartPurchasedList p {
  font-size: 0.8rem;
  font-weight: 600;
}
.courseInfoList{
    flex-wrap: wrap;
    margin: 0;
    align-items: center;
}
.courseInfoList p{
font-weight: 600;   
}
.infoList{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    border-bottom: none;
    padding: 0;
}
.infoList p, .infoList span{
    font-size: .7rem;
}
.infoIconList:first-child{
    font-size: 1rem;
}

.courses-list-detail {
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.courses-list-detail > .list-item:hover {
  background-color: rgb(34, 36, 34) !important;
}

.courses-list-detail > .list-item {
  display: inline-flex;
  padding-left: 12px;
  padding-right: 12px;
  gap: 5px;
  width: 100%;
}
.videoplayer {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  border-radius: 12px;
  overflow: hidden;
}
.accordion-text {
  display: inline-flex;
  gap: 10;
  align-items: center;
  color: #3784F8;
}
.middle-line {
  position: relative;
  align-items: center;
  display: inline-flex;
  gap: 5px;
  color: #D4D3D3;
}

.middle-line::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #D4D3D3;
}

.content-list-item {
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  font-size: 20px;
  text-decoration: none;
  color: inherit;
  padding: 10px 5px;
}

.content-list-item:hover {
  background-color: rgb(239, 239, 239);
}

@media screen and (min-width: 320px) {
  .courseHeadingLeft {
    width: 85%;
  }
  .courseName p {
    font-size: 0.8rem;
  }
  .courseRatingInfo p,
  .courseRatingInfo span {
    font-size: 0.6rem;
  }
  .infoIcon {
    font-size: 1.4rem;
    margin-right: 5px;
  }
}
@media screen and (min-width: 350px) {
  .courseHeadingLeft {
    width: 80%;
  }
  .courseName p {
    font-size: 0.8rem;
  }
  .courseRatingInfo p,
  .courseRatingInfo span {
    font-size: 0.6rem;
  }
}
@media screen and (min-width: 375px) {
  .courseHeadingList p,
  .courseHeadingList span {
    font-size: 0.7rem;
  }
  .courseDescList p {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 680px) {
  .courses {
    justify-content: space-between;
  }
  .courseHeadingList {
    max-width: 800px;
  }
  .courseHeadingList p,
  .courseHeadingList span {
    font-size: 0.8rem;
  }
  .courseNamList{
    justify-content: flex-start;
  }
  .courseRatingInfoList{
    justify-content: flex-start;
  }
  .starIconList{
    margin-right:  10px;
  }
  .courseDescList {
    margin: 20px 0;
  }
  .boardIcon {
    width: 20px; 
    height: 20px; 
  }
  .courseDescList p {
    font-size: 1rem;
  }
  .courseHeading2List p{
    font-size: .7rem;
  }
  .courseDesc3 p{
    font-size: 1.3rem;
  }
  .courseDescList2 p{
    font-size: 1rem;
  }
  .infoIconList{
    font-size: 1.1rem;
  }
  .infoIconList:first-child{
    font-size: 1.2rem;
  }
  .boardIcon2{
    width: 23px;
    height: 23px;
    margin-right: 5px;
  }
}
@media screen and (min-width: 700px) {
    .cardWrapperList3{
       flex-wrap: nowrap;
       align-items: flex-start;
    }
    .listInfoLeft{
        width: 70%;
        justify-content: space-between;
    }
    .listInfoLeft2{
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
    }
    .courseInfoList{
        justify-content: flex-start;
        margin-top: 0;
    }
    .infoList{
        margin-right: 10px;
    }
    .purchaseInfoList2{
        width: 30%;
        height: 90%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-end; 
    }
    .priceWrapperList{
        flex-direction: column;
        align-items: flex-end;
    }
    .priceList2:last-child{
        margin-top: 10px;
    }
    .priceList2  p{
        font-size: 1.8rem;
    }
    .priceList2:last-child  p{
        font-size: 1.3rem;
    }
    .rupeeIconList2{
        font-size: 1.5rem;
    }
    .wishlistIconWrapperList2{
        width: 30px;
        height: 30px;
    }.heartIconList2{
        font-size: 1rem;
    }
    .cartList2, .cartPurchasedList2{
        padding: 10px 0;
        width: 200px;
    }
    
}
@media screen and (min-width: 900px) {
    .cardList{
        justify-content: space-between;
    }
  .listInfoLeft {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .listContent {
    width: 70%;
  }
  .imgWrapperList {
    margin-right: 20px;
  }
  .courseDescList p {
    font-size: 1.1rem;
  }
  .purchaseInfoList {
    width: 30%;
  }
  .priceWrapper {
    flex-direction: column;
    align-items: flex-end;
  }
  .priceList p {
    font-size: 1.7rem;
  }
  .rupeeIconList {
    font-size: 1.6rem;
  }
  .priceList:last-child {
    margin-top: 10px;
  }
  .priceList:last-child p {
    font-size: 1.4rem;
  }
  .cartWrapper {
    width: 100%;
    margin-top: 0;
    justify-content: flex-end;
  }
  .courseHeadingList2 {
    padding: 20px 20px 0 20px;
}
.courseDescList2, .courseInfoList, .purchaseInfoList2{
    padding: 0 20px;
}

}
@media screen and (min-width: 1024px) {
    .cardWrapperList{
        padding: 20px;
    }
  .courseHeadingList p,
  .courseHeadingList span {
    font-size: 0.9rem;
  }
  .wishlistIconWrapperList{
    width: 40px;
    height: 40px;
  }
  .heartIconList{
    font-size: 1.4rem;
  }
  .cartList, .cartPurchasedList{
    padding: 15px;
  }
  .cartList p, .cartPurchasedList{
    font-size: .9rem;
  }

}
@media screen and (min-width: 1200px) {
    .listInfoLeft{
        width: calc(100% - 320px);
    }
}
@media screen and (min-width: 1400px) {
  .shortDesc p {
    font-size: 0.8rem;
    line-height: 20px;
  }
  .line {
    height: 130px;
  }
  .info p {
    font-size: 0.7rem;
  }
  .info1 p:last-child {
    font-size: 0.8rem;
  }
  .courseRatingInfo2 p,
  .courseRatingInfo2 span {
    font-size: 0.6rem;
  }
}
