.root{
    font-family: "Poppins", sans-serif;
}
#table{
    width: 97%;
    border-top: 2px solid black;
    border-spacing: 0;
    margin: 0 20px;
    font-family: "Poppins", sans-serif;
    position: relative;
}
#table thead {
    width: 100%;
    border-collapse:initial;
    padding: 10px 5px;
    height: 50px;
}
#table th{
    border-bottom: 2px solid black;
    font-weight: 600;
}
#table th svg {
    color: var(--gray);
   margin-bottom: -5px;
}
.dividerLine{
    height: 20px;
    display: inline-block;
}
.tableBody{
    width: 90%;
}
#table input[type="checkbox"]{
    width: 20px;
    height: 20px;
    border: 3px solid black;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tableBody tr{
    background-color: #f5f5f7;
    border-radius: 20px;
    height: 50px;
    padding: 45px;
    margin: 20px;
}
.tableBody td{
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
}
.tableBody td:nth-child(2) svg{
    background-color: #d9d9d9;
    border-radius: 3px;
    padding: 3px;
    margin-top: 3px;
}
.tableBody td:nth-child(3) {
    width: 100px;
}
.tableBody td button{
    border: none;
}
.tableBody td button svg, .tableBody td a svg{
    font-size: 1.4rem;
}
.tableActions{
    display: flex;
    align-items: center;
    justify-content: center;
}
.tableActions a{
    text-decoration: none;
}
.tableFooter{
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: space-between;
    width: 320px;
    margin-right: 40px;
}
.pageWrapper{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tableFooter p{
    color: #7ca1a4;
    font-size: 1.1rem;
    font-weight: 600;
}
.pagination{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pagination span, .pageWrapper svg{
    font-size: 1.1rem;
    font-weight: 500;
}
.pageWrapper svg{
    font-size: 1.4rem;
}
.pageWrapper button{
    background: none;
    border: none;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.currentPage{
    background-color: #176876;
    color: white;
    border-radius: 5px;
    padding: 0px 5px;
}
.modalContent{
    width: 90%;
    max-width: 700px;
    background-color: #fff;
    border-radius: 5px;
}
.tableBodyMsg{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    width: 100%;
    margin-top: 50px;
    border: 1px solid red;
    /* position: absolute;
    top: 50%;
    margin-top: 20vh;
    left: 50%;
    margin-left: -45%; */
}
.tableBodyMsg p, .tableBodyMsg span, .tableBodyMsg svg{
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
}